import React from 'react'
import styled from 'styled-components'
// import HeadingMain from '../components/HeadingMain'

import StyledLink from '../components/styles/StyledLink'
import SEO from '../components/SEO'

const Container = styled.div`
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 120px;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const TopHeading = styled.h1`
  font-size: 38px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 60px;
  line-height: 1.2;
`

const List = styled.ul``

const ListItem = styled.li`
  margin-top: 44px;
`

const SubList = styled.ul`
  margin-left: 22px;
`

const SubListItem = styled.li`
  /* text-indent: -18px; */
  margin-bottom: 12px;
  display: flex;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
`

const Left = styled.div`
  min-width: 30px;
`

const Right = styled.div``

const Heading = styled.h1`
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 1.2px;
  margin-bottom: 12px;
`

const Paragraph = styled.p`
  margin-bottom: 20px;
`

const URL = styled.span`
  word-break: break-all;
  overflow-wrap: break-word;
`

const Datenschutzerklaerung = () => {
  return (
    <>
      <SEO title="Datenschutz" />
      <Container>
        <TopHeading>Datenschutz</TopHeading>

        <Paragraph>
          Der Schutz Ihrer persönlichen Daten und Ihrer Privatsphäre  ist uns sehr wichtig. Daher verarbeiten wir
          personenbezogene Daten natürlicher Personen auf Grundlage der gesetzlichen Bestimmungen. Nachstehend möchten
          wir Sie über die wichtigsten Aspekte dieser Datenverarbeitung wie folgt informieren:
        </Paragraph>

        <List>
          <ListItem>
            <Heading>1. VERANTWORTLICHER FÜR DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN</Heading>
            <Paragraph>
              Weingut Göschl GesbR, Am Kanal 4, 7122 Gols ist Verantwortlicher für die Verarbeitung Ihrer
              personenbezogenen Daten im Zusammenhang mit sämtlichen Funktionalitäten der Website www.weingut-goeschl.at
              sowie der Abwicklung von Bestellungen, die per E-Mail oder Bestellformular getätigt werden. Neben der
              postalischen Kontaktmöglichkeit erreichen sie uns per E-Mail unter{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
                office@weingut-goeschl.at
              </StyledLink>{' '}
              sowie telefonisch unter der Rufnummer{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="tel:+436644530988">
                0664/4530988
              </StyledLink>
              .
            </Paragraph>
          </ListItem>
          <ListItem>
            <Heading>2. KONTAKT MIT UNS</Heading>
            <Paragraph>
              Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen
              Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
              gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. 
            </Paragraph>
          </ListItem>
          <ListItem>
            <Heading>3. DATENVERARBEITUNG UND -SPEICHERUNG</Heading>
            <SubList>
              <SubListItem>
                <Left>a)</Left>
                <Right>
                  Sie können unsere Website grundsätzlich besuchen, ohne Angaben zu Ihrer Person zu machen. Dennoch
                  werden bei jeder Anforderung einer Datei durch Ihren Internetbrowser Zugriffsdaten (u.a. der Name der
                  abgerufenen Seite bzw. Datei, Datum und Uhrzeit, verwendeter Internetbrowser u.a.) anonymisiert
                  übersandt und von uns zu statistischen Zwecken ausgewertet. Eine Weitergabe dieser Daten an Dritte
                  erfolgt nicht.
                </Right>
              </SubListItem>
              <SubListItem>
                <Left>b)</Left>
                <Right>
                  Zur vertraglichen Abwicklung von Bestellungen verarbeiten wir personenbezogenen Daten (=Name,
                  Anschrift, E-Mail-Adresse, Telefonnummer und Faxnummer) unserer Kunden. Eine Datenübermittlung an
                  Dritte erfolgt nicht, mit Ausnahme der Übermittlung der Kreditkartendaten an die abwickelnden
                  Bankinstitute / Zahlungsdienstleister zum Zwecke der Abbuchung des Einkaufspreises, an das von uns
                  beauftragte Transportunternehmen / Versandunternehmen zur Zustellung der Ware sowie an unseren
                  Steuerberater zur Erfüllung unserer steuerrechtlichen Verpflichtungen. Diese Daten werden bis zum
                  Ablauf der steuer- und unternehmensrechtlichen Aufbewahrungspflichten (7 Jahre) bei uns gespeichert.
                  Die Daten Name, Anschrift, gekaufte Waren und Kaufdatum werden darüber hinaus bis zum Ablauf der
                  Produkthaftung (10 Jahre) gespeichert.  Die Datenverarbeitung erfolgt auf Basis der gesetzlichen
                  Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder lit b (notwendig
                  zur Vertragserfüllung) der DSGVO.  
                </Right>
              </SubListItem>
              <SubListItem>
                <Left>c)</Left>
                <Right>
                  Wenn Sie ihre Einwilligung dazu erteilt haben, dass wir die in der abgegebenen Einwilligungserklärung
                  näher definierten personenbezogenen Daten zwecks Kontaktaufnahme per Brief, Telefon, SMS/Messenger
                  oder E-Mail zur Information über unser aktuelles Sortiment, Angebote oder Events verarbeiten (=
                  speichern und verwenden) dürfen, so kann dies jederzeit gegenüber der Weingut Göschl GesbR widerrufen
                  werden. Mit Erhalt des Widerrufs werden Ihre Daten gelöscht.
                </Right>
              </SubListItem>
              <SubListItem>
                <Left>d)</Left>
                <Right>
                  Wir nutzen die von Ihnen bekanntgegebenen Daten auch für Direktwerbung (z.B. für Postzusendungen). Sie
                  können der Datenverarbeitung für Direktwerbung jederzeit kostenlos durch eine Nachricht an{' '}
                  <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
                    office@weingut-goeschl.at
                  </StyledLink>{' '}
                  widersprechen. Im Fall eines Widerspruchs werden wir Ihre Daten nicht mehr für diesen Zweck
                  verarbeiten.
                </Right>
              </SubListItem>
              <SubListItem>
                <Left>e)</Left>
                <Right>
                  Wir verwenden die von Ihnen mitgeteilten Daten zur Vertragserfüllung streng nach den gesetzlichen
                  Vorschriften. Zu diesem Zweck erfolgt, soweit erforderlich, eine Weitergabe Ihrer Daten an andere
                  Unternehmen, z.B. die Post oder andere Paketdienste und Zahlungsdienst-Anbieter. Diese Unternehmen
                  dürfen Ihre Daten nur zur Abwicklung der konkreten Aufträge nutzen und sind uns gegenüber vertraglich
                  zur Einhaltung der gesetzlichen Vorschriften zum Datenschutz verpflichtet. Im Falle einer gesetzlichen
                  Vorschrift, einer behördlichen Anordnung oder eines amtlichen Ermittlungsverfahrens sind wir
                  verpflichtet, die jeweiligen Daten der Behörde zur Verfügung zu stellen.
                </Right>
              </SubListItem>

              <SubListItem>
                <Left>f)</Left>
                <Right>
                  Newsletter: Um Ihnen regelmäßig Informationen zu unserem Unternehmen und unseren Angeboten übermitteln
                  zu können, bieten wir den Versand eines Newsletters an. Sie haben die Möglichkeit, über unsere Website
                  unseren Newsletter zu abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre Erklärung, dass
                  Sie mit dem Bezug des Newsletters einverstanden sind. Mit Ihrer Anmeldung zum Newsletter verarbeiten
                  wir dabei die von Ihnen eingegebenen Daten (E-Mail-Adresse sowie sonstige freiwillige Angaben).
                  <div style={{ paddingBottom: '12px' }} />
                  Zur Gewährleistung einer einverständlichen Newsletter-Versendung nutzen wir das sogenannte
                  Double-Opt-in-Verfahren. Im Zuge dessen lässt sich der potentielle Empfänger in einen Verteiler
                  aufnehmen. Anschließend erhält der Nutzer durch eine Bestätigungs-E-Mail die Möglichkeit, die
                  Anmeldung rechtssicher zu bestätigen. Nur wenn die Bestätigung erfolgt, wird die Adresse aktiv in den
                  Verteiler aufgenommen. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten
                  Informationen und Angebote.
                  <div style={{ paddingBottom: '12px' }} />
                  Sie können sich jederzeit vom Newsletter über die Abmeldefunktion oder via Benachrichtigung via E-Mail
                  an{' '}
                  <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
                    office@weingut-goeschl.at
                  </StyledLink>{' '}
                  vom Newsletter-Service abmelden. Wir löschen anschließend umgehend Ihre Daten im Zusammenhang mit dem
                  Newsletter-Versand. Durch diesen Widerruf wird die Rechtmäßigkeit der aufgrund der Zustimmung bis zum
                  Widerruf erfolgten Verarbeitung nicht berührt. Als Newsletter Software wird{' '}
                  <StyledLink
                    style={{ whiteSpace: 'nowrap' }}
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.brevo.com/"
                  >
                    Brevo
                  </StyledLink>{' '}
                  verwendet. Ihre Daten werden dabei an die Sendinblue GmbH übermittelt. Brevo bzw. Sendinblue ist es
                  dabei untersagt, Ihre Daten zu verkaufen und für andere Zwecke, als für den Versand von Newslettern zu
                  nutzen. Brevo bzw. Sendinblue ist ein deutscher, zertifizierter Anbieter, welcher nach den
                  Anforderungen der Datenschutz-Grundverordnung und des Bundesdatenschutzgesetzes ausgewählt wurde.
                  <div style={{ paddingBottom: '12px' }} />
                  Weitere Informationen finden Sie hier: <URL>https://www.brevo.com/de/legal/privacypolicy/</URL>
                  <div style={{ paddingBottom: '12px' }} />
                  Die datenschutzrechtlichen Maßnahmen unterliegen stets technischen Erneuerungen aus diesem Grund
                  bitten wir Sie, sich über unsere Datenschutzmaßnahmen in regelmäßigen Abständen durch Einsichtnahme in
                  unsere Datenschutzerklärung zu informieren.
                  {/* Newsletter: Um Ihnen regelmäßig Informationen zu unserem Unternehmen und unseren Angeboten übermitteln
                  zu können, bieten wir den Versand eines Newsletters an. Sie haben die Möglichkeit, über unsere Website
                  unseren Newsletter zu abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre Erklärung, dass
                  Sie mit dem Bezug des Newsletters einverstanden sind. Mit Ihrer Anmeldung zum Newsletter verarbeiten
                  wir dabei die von Ihnen eingegebenen Daten (E-Mail-Adresse sowie sonstige freiwillige Angaben).
                  <div style={{ paddingBottom: '12px' }} />
                  Zur Gewährleistung einer einverständlichen Newsletter-Versendung nutzen wir das sogenannte
                  Double-Opt-in-Verfahren. Im Zuge dessen lässt sich der potentielle Empfänger in einen Verteiler
                  aufnehmen. Anschließend erhält der Nutzer durch eine Bestätigungs-E-Mail die Möglichkeit, die
                  Anmeldung rechtssicher zu bestätigen. Nur wenn die Bestätigung erfolgt, wird die Adresse aktiv in den
                  Verteiler aufgenommen.
                  <div style={{ paddingBottom: '12px' }} />
                  Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und Angebote.
                  <div style={{ paddingBottom: '12px' }} />
                  Sie können sich jederzeit vom Newsletter über die Abmeldefunktion oder via Benachrichtigung via E-Mail
                  an{' '}
                  <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
                    office@weingut-goeschl.at
                  </StyledLink>{' '}
                  vom Newsletter-Service abmelden. Wir löschen anschließend umgehend Ihre Daten im Zusammenhang mit dem
                  Newsletter-Versand. Durch diesen Widerruf wird die Rechtmäßigkeit der aufgrund der Zustimmung bis zum
                  Widerruf erfolgten Verarbeitung nicht berührt.
                  <div style={{ paddingBottom: '12px' }} />
                  Als Newsletter Software wird{' '}
                  <StyledLink
                    style={{ whiteSpace: 'nowrap' }}
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.newsletter2go.at/"
                  >
                    Newsletter2Go
                  </StyledLink>{' '}
                  verwendet. Ihre Daten werden dabei an die Newsletter2Go GmbH übermittelt. Newsletter2Go ist es dabei
                  untersagt, Ihre Daten zu verkaufen und für andere Zwecke, als für den Versand von Newslettern zu
                  nutzen. Newsletter2Go ist ein deutscher, zertifizierter Anbieter, welcher nach den Anforderungen der
                  Datenschutz-Grundverordnung und des Bundesdatenschutzgesetzes ausgewählt wurde.
                  <div style={{ paddingBottom: '12px' }} />
                  Weitere Informationen finden Sie hier: 
                  <URL>https://www.newsletter2go.de/informationen-newsletter-empfaenger/</URL>
                  <div style={{ paddingBottom: '12px' }} />
                  Die datenschutzrechtlichen Maßnahmen unterliegen stets technischen Erneuerungen aus diesem Grund
                  bitten wir Sie, sich über unsere Datenschutzmaßnahmen in regelmäßigen Abständen durch Einsichtnahme in
                  unsere Datenschutzerklärung zu informieren. */}
                </Right>
              </SubListItem>

              <SubListItem>
                <Left>g)</Left>
                <Right>
                  Cookies: Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien,
                  die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir
                  nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem
                  Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten
                  Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass
                  er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der
                  Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                </Right>
              </SubListItem>

              <SubListItem>
                <Left>h)</Left>
                <Right>
                  Web-Analyse: Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
                  ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert
                  werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie
                  erzeugten Informationen über Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) wird an
                  einen Server von Google in den USA übertragen und dort gespeichert. Ihre IP-Adresse wird erfasst, aber
                  umgehend (z.B. durch Löschung der letzten 8 Bit) pseudonymisiert. Dadurch ist nur mehr eine grobe
                  Lokalisierung möglich. Google wird diese Informationen benutzen, um Ihre Nutzung der Website
                  auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
                  weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
                  wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                  vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem
                  Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation
                  der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie
                  jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                  voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung
                  der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor
                  benannten Zweck einverstanden.
                </Right>
              </SubListItem>
            </SubList>
          </ListItem>
          <ListItem>
            <Heading>4. IHRE RECHTE</Heading>
            <Paragraph>
              Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft,
              Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Sollten Sie der
              Meinung sein, dass wir Ihre Daten unrechtmäßig verarbeiten, so ersuchen wir Sie, zur umgehenden Klärung
              mit uns Kontakt aufzunehmen. Wir gehen davon aus, dass wir die meisten Fragen direkt beantworten können.
              Daneben haben Sie auch das Recht, sich direkt bei der österreichischen Datenschutzbehörde zu beschweren.
              Ihr Vertrauen ist uns wichtig. Wir möchten Ihnen daher jederzeit Rede und Antwort bezüglich der
              Verarbeitung Ihrer personenbezogenen Daten stehen. Wenn Sie weitere Fragen haben, stehen wir Ihnen
              jederzeit gerne zur Verfügung.
            </Paragraph>
          </ListItem>
        </List>
      </Container>
    </>
  )
}

export default Datenschutzerklaerung
